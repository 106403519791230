html, body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  text-transform: lowercase;
}

.root {
  min-height: 100vh;
  display: flex;
}

.background {
  flex: 1;
}

a {
  color: inherit;
}

.contentContainer {
  max-width: 600px;
  margin: 100px auto;
  width: calc(100vw - 100px);
}

@media (max-width: 900px) {
  .contentContainer {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .contentContainer {
    margin-top: 20px;
    max-width: calc(100vw - 50px);
  }
}

.indexContainer {
  width: 400px;
  max-width: calc(100vw - 100px);
  margin: 50px auto;
  margin-top: calc(50vh - 250px);
}

.indexContainer h1 {
  text-align: center;
  font-size: 36px;
  letter-spacing: .5em;
}

.indexContainer p {
  text-align: justify;
  text-justify: inter-word;
  font-size: 32px;
}

.indexContainer p.link {
  text-align: center;
}

@media (max-width: 450px) {
  .indexContainer h1 {
    font-size: 9vw;
  }

  .indexContainer p {
    text-align: left;
  }
}

.spinner > span:not(.word) {
  margin-left: 2px;
  animation: bounce 3s infinite;
  transform-origin: center bottom;
  display: inline-block;
}

.spinner > span:nth-child(3) {
  animation-delay: .1s;
}

.spinner > span:nth-child(4) {
  animation-delay: .2s;
}

@keyframes bounce {
  0%,
  10%,
  26%,
  50% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  20%,
  21% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0) scaleY(1.1);
  }

  35% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0) scaleY(1.05);
  }

  40% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  45% {
    transform: translate3d(0, -2.6667px, 0) scaleY(1.02);
  }
}
.date {
  font-style: italic;
}

.contentContainer blockquote {
  margin-left: 16px;
  border-left: solid 4px currentColor;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.contentContainer blockquote > p:first-child {
  margin-top: 0;
}

.contentContainer blockquote > p:last-child {
  margin-bottom: 0;
}

.contentContainer p img {
  max-width: 100%;
}

.contentContainer .preserveCase ~ p,
.contentContainer .preserveCase ~ blockquote,
.contentContainer .preserveCase ~ ol,
.contentContainer .preserveCase ~ ul,
.preserveCase {
  text-transform: initial;
}

.contentContainer .preserveSpace ~ p,
.contentContainer .preserveSpace ~ blockquote,
.preserveSpace {
  white-space: pre;
}

.contentContainer p > code {
  padding: 1px 4px;
  position: relative;
  top: -2px;
  border-radius: 3px;
  background: #ffdaf0;
  font-family: monospace;
  font-size: 13px;
  font-weight: 300;
  transition: background-color .6s ease-in-out;
  text-transform: initial;
}

.dark + .background .contentContainer p > code {
  background: #513854;
}

.contentContainer pre code {
  display: block;
  width: calc(100%-16px);
  padding: 8px;
  border-radius: 4px;;
  background: #ffeee9;
  overflow-x: auto;
  transition: background-color .6s ease-in-out;
  text-transform: initial;
}

.contentContainer hr {
  border: none;
  border-bottom: solid 1px currentColor;
  margin: 36px 0;
}

.dark + .background .contentContainer pre code {
  background: #32445b;
}

@media (max-width: 600px) {
  .contentContainer blockquote {
    margin-left: 0;
    margin-right: 4px;
  }
}

.encryptedContent{
  position: relative;
}

.encryptedContent .contentBackground {
  filter: blur(5px);
  opacity: .8;
  pointer-events: none;
  user-select: none;
}

.encryptedContent .encryptedBanner {
  overflow:hidden;
  display: block;
  position: absolute;
  top: 50px;
  left: calc((100% - 100vw + var(--scrollbar-width, 0px)) / 2);
  right: calc((100% - 100vw + var(--scrollbar-width, 0px)) / 2);
  max-width: 100vw;
  min-height: 200px;
  background: rgba(0,0,0,.6);
}

.encryptedContent form {
  color: white;
  display: block;
  position: absolute;
  padding: 4px 0;
  top: 50px;
  width: 100%;
  text-align: center;
}

.encryptedContent form p {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}


.encryptedContent form input {
  font-size: 16px;
  background: none;
  border: solid 1px currentColor;
  box-shadow: none;
  color: inherit;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.encryptedContent form button {
  font-size: 16px;
  background: none;
  border: solid 1px currentColor;
  box-shadow: none;
  color: inherit;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  background: rgba(255,255,255,.2);
  cursor: pointer;
}

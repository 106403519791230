.nav {
  display: flex;
  height: 50px;
  padding: 12px;
  box-sizing: border-box;

  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
}

.color {
  color: rgba(0,0,0,.8);
}

.color.dark {
  color: white;
}

.color + .background {
  transition: .6s ease-in-out;
  transition-property: background-color, color;
}

.color.dark + .background {
  background: #29262f;
  color: rgba(255,255,255,.6);
}

.color.dark + .background h1 {
  color: rgba(255,255,255,.9);
  transition: .6s ease-in-out color;
}

.color.dark + .background strong, .color.dark + .background a, .color.dark + .background svg {
  color: rgba(255,255,255,.8);
  transition: .6s ease-in-out color;
}

.color::before {
  opacity: 1;
  transition: opacity .6s;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgba(231,0,0,1);
  background-image: linear-gradient( 112.6deg,  rgba(56,24,40,1) -10.7%, rgba(23,148,185,1) 100.2% );
  z-index: -1;
}

.color:not(.dark)::before {
  opacity: 0;
}

.nav a.homeLink, .nav .navButton {
  line-height: 16px;
  text-decoration: none;
  font-weight:300;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  display: inline-block;
  opacity: 1;
}

.color:not(.dark) .nav .navButton {
  color: rgba(0,0,0,.4);
}

.nav .navButton:not(:last-child) {
  margin-right: 16px;
}

.nav a.hidden {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: rgba(223,181,251,1);
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(223,181,251,1) 0%, rgba(251,181,246,1) 35.5%, rgba(251,207,181,1) 63.5%, rgba(250,253,197,1) 90% );
  transition: .6s ease-in-out;
  transition-property: height background-color background-image;
  color: rgba(0,0,0,.8);
  overflow: hidden;
  z-index: 2;
}

.color.expanded {
  height: 100vh;
}

.color + div {
  padding-top: 50px
}

@media (prefers-reduced-motion) {
  .color {
    transition: none;
  }
}
